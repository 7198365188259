#front {
  #front-slideshow {
    .embed-responsive-item {
      background: {
        size: 100%;
        size: cover;
        position: center;
        repeat: no-repeat;
      }
    }
  }
}