img {
  margin-bottom: 0.375em;
}

.alignleft {
  // margin: {
  //   left: auto;
  //   right: auto;
  // }

  // display: block;

  @include media-breakpoint-up(md) {
    float: left !important;
    margin-right: 0.75em;
  }
}

.alignright {
  // margin: {
  //   left: auto;
  //   right: auto;
  // }

  // display: block;
  
  @include media-breakpoint-up(md) {
    float: right !important;
    margin-left: 0.75em;
  }
}

.aligncenter {
  display: block;
  margin: {
    left: auto;
    right: auto;
  }
}