#header {
  font-family: 'Open Sans', sans-serif;
  .d-block.d-md-none {
    .navbar-brand img {
      height: 4rem;
    }
  }

  .d-md-block {
    .nav-item {
      position: relative;
      &:not(:first-child):before {
        content: "";
        width: 1px;
        height: 1.75em;
        position: absolute;
        top: 0.25em;
        display: inline-block;
        border-right: solid 1px $black;
      }
      
      .nav-link {
        display: inline-block;
        color: $black;
      }

      &.show {
        background: $primary;

        & + .nav-item:before,
        &:before {
          display: none;
        }

        margin: {
          left: 0;
          right: 0;
        }
    
        .nav-link {
          color: $white;
        }
      }
    }
  }
}